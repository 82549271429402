import React, { useEffect, useRef, useState } from "react";
import Popup from "./popup";

const Modal = ({
  isOpen,
  onClose,
  children,
  modalstyle,
  modaloverlay = "modal-overlay",
  limitClose = false,
  classNameBuy,
  noClose = false,
}) => {
  // console.log("hi from popup");
  const [closeAnimation, setCloseAnimation] = useState(false);
  const popupRef = useRef(null);

  useEffect(() => {
    document.querySelector("body").classList.add("ovaerlay_body");
    if (isOpen && !limitClose) {
      document.querySelector("body").classList.add("ovaerlay_body");
      setCloseAnimation(false);
      // console.log("isOpen", isOpen);
    }

    if (isOpen) {
      setCloseAnimation(false);
    }

    if (!isOpen) {
      document.querySelector("body").classList.remove("ovaerlay_body");
      // console.log("isOpen", isOpen);
    }
    const handleClickOutside = (e) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(e.target) &&
        !limitClose
      ) {
        handleClose(e);
      }
    };
    // setCloseAnimation(closeAnimation);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const handleClose = (e) => {
    e.preventDefault();
    setCloseAnimation(true);
    // console.log("animation", closeAnimation);
    // onClose();
    setTimeout(() => onClose(), 500);

    document.querySelector("body").classList.remove("ovaerlay_body");
  };

  if (!isOpen) return null;

  return (
    <Popup>
      <div className={isOpen ? modaloverlay : ""}>
        <div className="modal-container">
          <div className="modal-content">
            <div
              className={`${modalstyle} ${
                closeAnimation ? "scale-out-center-out" : ""
              }`}
              ref={popupRef}
            >
              {!noClose ? (
                <button
                  title="Close (Esc)"
                  type="button"
                  className={`mfp-close ${classNameBuy}`}
                  onClick={handleClose}
                >
                  ×
                </button>
              ) : null}

              {children}
            </div>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default React.memo(Modal);
