import {
  GET_ALL_WALLET_BALANCE,
  GET_ALL_WALLET_BALANCE_SUCCESS,
  GET_ALL_WALLET_BALANCE_ERROR,
  RESET_ALL_WALLET_BALANCE_STATES,
  RESET_ALL_WALLET_BALANCE_DATA_FLAG,
} from "./actionTypes";

//getMyProfile
export const getAllWalletBalanceAction = (user) => {
  return {
    type: GET_ALL_WALLET_BALANCE,
    payload: { user },
  };
};

export const getAllWalletBalanceActionSuccess = (user) => {
  return {
    type: GET_ALL_WALLET_BALANCE_SUCCESS,
    payload: user,
  };
};

export const getAllWalletBalanceActionError = (error) => {
  return {
    type: GET_ALL_WALLET_BALANCE_ERROR,
    payload: error,
  };
};

//resetFlags
export const resetAllWalletBalanceAction = () => {
  return {
    type: RESET_ALL_WALLET_BALANCE_STATES,
  };
};

export const resetAllAllWalletBalanceActionFlag = () => {
  return {
    type: RESET_ALL_WALLET_BALANCE_DATA_FLAG,
  };
};
