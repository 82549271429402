// actions.js
import {
  ALL_CATEGORIES,
  ALL_CATEGORIES_SUCCESS,
  ALL_CATEGORIES_ERROR,
  RESET_HOME_ALL_CATEGORIES,
  //TOP
  TOP_CATEGORIES,
  TOP_CATEGORIES_SUCCESS,
  TOP_CATEGORIES_ERROR,
} from "./actionTypes";

//ALL
export const seedCategoriesData = (data) => {
  return {
    type: ALL_CATEGORIES,
    payload: data,
  };
};

export const seedCategoriesDataSuccess = (data) => {
  return {
    type: ALL_CATEGORIES_SUCCESS,
    payload: data,
  };
};

export const seedCategoriesDataError = (error) => {
  return {
    type: ALL_CATEGORIES_ERROR,
    payload: error,
  };
};

//TOP

export const topCategoriesData = (data) => {
  return {
    type: TOP_CATEGORIES,
    payload: data,
  };
};

export const topCategoriesDataSuccess = (data) => {
  return {
    type: TOP_CATEGORIES_SUCCESS,
    payload: data,
  };
};

export const topCategoriesDataError = (error) => {
  return {
    type: TOP_CATEGORIES_ERROR,
    payload: error,
  };
};

export const ResetAllCategories = () => ({
  type: RESET_HOME_ALL_CATEGORIES,
});
