import Web3 from "web3";
import { getBalance } from "@wagmi/core";
import { TransactionBlock } from "@mysten/sui.js/transactions";

// export var injectedProvider = false;

import {
  chainIdd,
  config3,
  formatBalance,
  onChangeChain,
  setChainId,
  wagmiConfig,
  web3auth,
} from "../web3Utiles";
import { chainList, walletType } from "@/components/constants/layout";
import {
  getNftCheckLock,
  getNftUpdateMarketPlaceId,
  postToggleLockNft,
} from "../backend_helper";
import { forceDiconnect, logStage } from "@/components/common/utiles";

export var web3Provider;

export var web3AuthProvider;
export var web3AtuhUserInfo;
export var web3AtuhWalletClient;

export var currentUser;
export var currentUserSui;

export var nft_contract;
export var seed_marketplace_contract;
export var bridge_contract;

export var museum_ETHAddress = "";
export var wallet_LockAndEarn = "";
export var Wallet_Staking = "";

export var nft_contract_address = "";
export var seed_marketplace_contract_address = "";
export var bridge_contract_address = "";

export var nft_contract_abi = "";
export var seed_marketplace_contract_abi = "";
export var bridge_contract_abi = "";

export var nft_contract_abi_json = {};
export var seed_marketplace_contract_abi_json = {};
export var bridge_contract_abi_json = {};
export var ipfs_url = "";

export var _marketPlaceOwner = "0x581f84D51110bd5FC30cd7c9B255956dE868b31b";

//TODO this might get from api
export var packageObjectId = "";
export var _seedMarketPlaceSui = "";
export const gasFee = 50000001;

export var rpc = "";

export const sign_message =
  "I confirm using my wallet for the SEED.photo platform.";

var r = {};
r[chainIdd] = rpc;

const initialState = { accounts: [], userBalance: "", chainId: "" };
export var wallet = initialState;

export function setWallet(accounts, userBalance, chainId) {
  wallet.accounts = accounts;
  wallet.userBalance = userBalance;
  wallet.chainId = chainId;
}

export function setCurrentUser(address) {
  currentUser = address;
}

export function setCurrentUserSui(address) {
  currentUserSui = address;
}

async function initContractAndAddress(nftContract = null) {
  try {
    if (config3) {
      if (isNaN(chainIdd)) {
        setChainId(chainList.BSCMainnet);
        rpc = "https://bsc-dataseed.binance.org/";
      } else {
        try {
          rpc = config3[chainIdd.toString()]["rpc"];
          if (
            chainIdd === chainList.BSCMainnet ||
            chainIdd === chainList.BSCTestnet
          ) {
            var nft_contract_address_array =
              config3[chainIdd.toString()]["nft_contract_address"];
            var nft_contract_abi_array =
              config3[chainIdd.toString()]["nft_contract_abi"];

            if (nftContract === null) {
              nft_contract_address =
                nft_contract_address_array[
                  nft_contract_address_array.length - 1
                ];
              nft_contract_abi =
                nft_contract_abi_array[nft_contract_abi_array.length - 1];
            } else {
              let initNftContractAddress = nft_contract_address_array.map(
                (item) => item.toLowerCase()
              );

              nft_contract_address =
                initNftContractAddress[
                  initNftContractAddress.indexOf(nftContract.toLowerCase())
                ];

              nft_contract_abi =
                nft_contract_abi_array[
                  initNftContractAddress.indexOf(nftContract.toLowerCase())
                ];
            }
          } else {
            nft_contract_address =
              config3[chainIdd.toString()]["nft_contract_address"];
            nft_contract_abi = config3[chainIdd.toString()]["nft_contract_abi"];
          }

          seed_marketplace_contract_address =
            config3[chainIdd.toString()]["seed_marketplace_contract_address"];
          seed_marketplace_contract_abi =
            config3[chainIdd.toString()]["seed_marketplace_contract_abi"];

          bridge_contract_address =
            config3[chainIdd.toString()]["bridge_contract_address"];
          bridge_contract_abi =
            config3[chainIdd.toString()]["bridge_contract_abi"];
          museum_ETHAddress = config3[chainIdd.toString()]["museum_ETHAddress"];
          wallet_LockAndEarn = config3["wallet_LockAndEarn"];
          Wallet_Staking = config3["Wallet_Staking"];

          nft_contract_abi_json = JSON.parse(
            nft_contract_abi.replace(/'/g, '"')
          );
          seed_marketplace_contract_abi_json = JSON.parse(
            seed_marketplace_contract_abi.replace(/'/g, '"')
          );
          bridge_contract_abi_json = JSON.parse(
            bridge_contract_abi.replace(/'/g, '"')
          );
          _marketPlaceOwner = config3[chainIdd.toString()]["marketPlaceOwner"];
          ipfs_url = config3[chainIdd.toString()]["ipfsUrl"];
          // console.log("rpc", rpc);
        } catch (error) {
          console.log(error);
          handleChangeChainForConfig();
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

//* Initial Sui data
export async function initSuiContractAndAddress() {
  try {
    if (config3) {
      try {
        packageObjectId = config3["Nft_PackageId_Sui"];
        _seedMarketPlaceSui = config3["Seed_Marketplace_Sui"];
        ipfs_url = config3["ipfsUrl"];
        console.log(
          "initSuiContractAndAddress data",
          packageObjectId,
          _seedMarketPlaceSui,
          ipfs_url
        );
        // console.log("rpc", rpc);
      } catch (error) {
        console.log(error);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export async function initWeb3Auth() {
  if (web3auth.connected) {
    if (typeof web3AuthProvider === "undefined") {
      logStage("checkkkkkk web3AuthProvider", web3AuthProvider, currentUser);
      web3AuthProvider = new Web3(web3auth.provider);
    }

    if (typeof web3AtuhUserInfo === "undefined") {
      logStage("checkkkkkk web3AtuhUserInfo ", web3AtuhUserInfo);
      web3AtuhUserInfo = await web3auth
        .getUserInfo()
        .then((res) => res)
        .catch((err) => console.log(err));
      logStage("web3AtuhUserInfo ", web3AtuhUserInfo);
    }
  } else {
    forceDiconnect();
  }
}

export async function Web3AuthConnect() {
  await web3auth
    .connect()
    .then((res) => initWeb3Auth())
    .catch((err) => {
      console.error(err);
    });
}

async function initContractAndAddressByBit() {
  try {
    if (config3) {
      if (isNaN(chainIdd)) {
        setChainId(56);
        rpc = "https://bsc-dataseed.binance.org/";
      } else {
        try {
          rpc = config3[chainIdd.toString()]["rpc"];
          nft_contract_address =
            config3[chainIdd.toString()]["nft_contract_address_new"];
          nft_contract_abi =
            config3[chainIdd.toString()]["nft_contract_abi_new"];

          seed_marketplace_contract_address =
            config3[chainIdd.toString()]["seed_marketplace_contract_address"];
          seed_marketplace_contract_abi =
            config3[chainIdd.toString()]["seed_marketplace_contract_abi"];

          bridge_contract_address =
            config3[chainIdd.toString()]["bridge_contract_address"];
          bridge_contract_abi =
            config3[chainIdd.toString()]["bridge_contract_abi"];
          museum_ETHAddress = config3[chainIdd.toString()]["museum_ETHAddress"];

          nft_contract_abi_json = JSON.parse(
            nft_contract_abi.replace(/'/g, '"')
          );
          seed_marketplace_contract_abi_json = JSON.parse(
            seed_marketplace_contract_abi.replace(/'/g, '"')
          );
          bridge_contract_abi_json = JSON.parse(
            bridge_contract_abi.replace(/'/g, '"')
          );
          _marketPlaceOwner = config3[chainIdd.toString()]["marketPlaceOwner"];
          ipfs_url = config3[chainIdd.toString()]["ipfsUrl"];
          // console.log("rpc", rpc);
        } catch (error) {
          console.log(error);
          handleChangeChainForConfig();
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

async function handleChangeChainForConfig() {
  var alterChain = 56;
  if (chainIdd === chainList.BSCMainnet) {
    alterChain = chainList.BSCTestnet;
  } else if (chainIdd === chainList.BSCTestnet) {
    alterChain = chainList.BSCMainnet;
  } else if (chainIdd === chainList.EthereumMainnet) {
    alterChain = chainList.EthereumTestnet;
  } else if (chainIdd === chainList.EthereumTestnet) {
    alterChain = chainList.EthereumMainnet;
  } else if (chainIdd === chainList.PolygonMainnet) {
    alterChain = chainList.PolygonTestnet;
  } else {
    alterChain = chainList.PolygonMainnet;
  }

  await onChangeChain(alterChain);
}

export async function initSmartContract(nftContract = null, userOwner = null) {
  var nftContractData;

  try {
    if (config3) {
      nftContractData =
        config3[chainIdd.toString()]["nft_contract_address_new"];
    }
    if (
      userOwner ===
        "0x39057bBc670C57bf098543a41009fDeeD1c6C571".toLowerCase() ||
      userOwner ===
        "0x51f6E594d5fC6fC9b31623037ee9eA8603E7D171".toLowerCase() ||
      userOwner ===
        "0x4610F1069f00697bAc2951492A50C060a7C4e976".toLowerCase() ||
      userOwner ===
        "0x902D291587c54D430A55429CB2cE8f2A6484354b".toLowerCase() ||
      nftContract === nftContractData.toLowerCase()
    ) {
      await initContractAndAddressByBit();
    } else {
      await initContractAndAddress(nftContract);
    }
  } catch (error) {
    console.log(error);
    handleChangeChainForConfig();
  }

  let nft_contract_address_list =
    nftContract && nftContract !== null ? nftContract : nft_contract_address;

  // console.log("nft_contract_address_list iiiiiiii", nft_contract_address_list);

  console.log("configs3 initSmartContract", config3);
  // console.log("configs3 initSmartContract", config3);
  let loginType = JSON.parse(localStorage.getItem("loginType"));
  web3Provider = new Web3(window.ethereum);
  if (loginType === walletType.metamask) {
    if (config3 && rpc) {
      nft_contract = new web3Provider.eth.Contract(
        nft_contract_abi_json,
        nft_contract_address_list
      );
      seed_marketplace_contract = new web3Provider.eth.Contract(
        seed_marketplace_contract_abi_json,
        seed_marketplace_contract_address
      );
      bridge_contract = new web3Provider.eth.Contract(
        bridge_contract_abi_json,
        bridge_contract_address
      );
    } else {
      await initContractAndAddress(nftContract);
    }
  } else if (loginType === walletType.web3Auth) {
    if (config3 && rpc) {
      if (web3auth.connected) {
        initWeb3Auth();
        nft_contract = new web3AuthProvider.eth.Contract(
          nft_contract_abi_json,
          nft_contract_address_list
        );
        seed_marketplace_contract = new web3AuthProvider.eth.Contract(
          seed_marketplace_contract_abi_json,
          seed_marketplace_contract_address
        );
        bridge_contract = new web3AuthProvider.eth.Contract(
          bridge_contract_abi_json,
          bridge_contract_address
        );
      }
    } else {
      await initContractAndAddress(nftContract);
    }
  }
}

export async function CheckBalance(price, onFailureBalance, balance = null) {
  if (currentUser !== null) {
    var userWalletBalance;
    let loginType = JSON.parse(localStorage.getItem("loginType"));
    if (loginType === walletType.walletConnect) {
      var balanceUser = await getBalance(wagmiConfig, {
        address: currentUser,
      });
      var formatedBalance = formatBalance(balanceUser.value);
      userWalletBalance = parseFloat(formatedBalance).toFixed(4);
    } else if (loginType === walletType.metamask) {
      web3Provider = new Web3(window.ethereum);
      try {
        userWalletBalance = parseFloat(
          web3Provider.utils.fromWei(
            await web3Provider.eth.getBalance(currentUser),
            "ether"
          )
        ).toFixed(6);
      } catch (error) {
        console.log(error);
      }
    } else if (loginType === walletType.web3Auth) {
      initWeb3Auth();

      try {
        userWalletBalance = parseFloat(
          web3AuthProvider.utils.fromWei(
            await web3AuthProvider.eth.getBalance(currentUser),
            "ether"
          )
        ).toFixed(6);
      } catch (error) {
        console.log(error);
      }
    } else if (loginType === walletType.suiWallet) {
      userWalletBalance = (parseFloat(balance) / 1e9).toFixed(6);
    }

    if (userWalletBalance < price + 0.003) {
      //showLoading
      // alert("You don't have enough funds to cover the gas or blockchain fees.");
      onFailureBalance();
      return false;
    } else {
      console.log("from CheckBalance -> userWalletBalance", userWalletBalance);
      return true;
    }
  }
  return true;
}

export async function CheckLockNft(nftId) {
  let responseCheck = getNftCheckLock(nftId);
  if (responseCheck.data > 0) {
    alert("This Nft Is Locked.");
    location.reload();
    return;
  }
}

export async function getTransactionReceipt(
  provider,
  transactionHash,
  onSuccess
) {
  var isTxnNull = true;
  while (isTxnNull) {
    var txn = await provider.eth.getTransactionReceipt(
      transactionHash.transactionHash
    );
    if (txn !== null) {
      isTxnNull = false;
      if (txn.status) {
        onSuccess();
      } else {
        // showLoading();
        console.log("showLoading");
      }
    }
  }
}

//* SUI
export async function getSuiTransactionReceipt(transactionData, onSuccess) {
  var isTxnNull = true;
  while (isTxnNull) {
    // var txn = await web3Provider.eth.getTransactionReceipt(
    //   transactionHash.transactionHash
    // );
    if (transactionData !== null) {
      isTxnNull = false;
      if (transactionData.digest !== null) {
        onSuccess();
      } else {
        // showLoading();
        console.log("showLoading");
      }
    }
  }
}

export function ToggleLockNft(nftId, lockId) {
  const data = {
    NftId: nftId,
    LockId: lockId,
    Locker: typeof currentUser === "object" ? currentUser[0] : currentUser,
  };
  try {
    postToggleLockNft(data);
  } catch (error) {
    console.log(error);
  }
}

export async function updateMarketPlaceId() {
  //TODO-onSuccess
  getNftUpdateMarketPlaceId();
}

//* Add Nft and Token to wallet
export async function addToWallet() {
  await initSmartContract();

  const tokenAddress = "0x180Df54c534269df3384c08f43203471A80a6b44";
  const tokenSymbol = "SEED";

  const tokenDecimals = 0;

  let loginType = JSON.parse(localStorage.getItem("loginType"));
  if (loginType === walletType.metamask) {
    try {
      const isAdded = await web3Provider.currentProvider.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            // image: tokenImage, // if you have the image, it goes here
          },
        },
      });
    } catch (error) {
      // handle errors
      console.log(error);
    }
  } else {
    try {
      const isAdded = await window.ethereum?.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            // image: tokenImage, // if you have the image, it goes here
          },
        },
      });
    } catch (error) {
      // handle errors
      console.log(error);
    }
  }
}

export async function addTokenToWallet() {
  await initSmartContract();
  let tokenAddress = "0x4d041f0860dA3916Dc0367f2113e744396Fc1e5B";
  if (
    process.env.NEXT_PUBLIC_URL.toLowerCase().includes("seed.photo") ||
    process.env.NEXT_PUBLIC_URL.toLowerCase().includes("stage3") ||
    process.env.NEXT_PUBLIC_URL.toLowerCase().includes("stage2")
  ) {
    tokenAddress = "0x6730f7A6BbB7b9C8e60843948f7FEB4B6a17B7F7";
    await onChangeChain(chainList.BSCMainnet);
  } else {
    await onChangeChain(chainList.BSCTestnet);
  }

  const tokenSymbol = "SEED";
  const tokenDecimals = 18;

  let loginType = JSON.parse(localStorage.getItem("loginType"));
  if (loginType === walletType.metamask) {
    try {
      const isAdded = await web3Provider.currentProvider.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            // image: tokenImage, // if you have the image, it goes here
          },
        },
      });
    } catch (error) {
      // handle errors
      console.log(error);
    }
  } else {
    try {
      const isAdded = await window.ethereum?.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            // image: tokenImage, // if you have the image, it goes here
          },
        },
      });
    } catch (error) {
      // handle errors
      console.log(error);
    }
  }
}

//* SUI transferCoin actions ----
export async function createObjectRef(client, suiWallet) {
  let sortBaseHighestBalance;
  let coins = await client
    .getCoins({
      owner: suiWallet.account.address,
    })
    .then((res) => {
      sortBaseHighestBalance = res.data.sort(function (a, b) {
        return b.balance - a.balance;
      });
      console.log(res);
    })
    .catch((err) => console.log(err));

  // console.log(coins);

  // console.log("coins list", coins.data);
  console.log("sortBaseHighestBalance", sortBaseHighestBalance);

  const objectReft = [
    {
      digest: sortBaseHighestBalance[0].digest,
      objectId: sortBaseHighestBalance[0].coinObjectId,
      version: sortBaseHighestBalance[0].version,
    },
  ];

  console.log("objectReft", objectReft);

  return objectReft;
}

export async function transferCoinTxnBlock(client, suiWallet) {
  const txb = new TransactionBlock();

  const objectRefInstance = await createObjectRef(client, suiWallet);

  txb.setGasPayment(objectRefInstance);
  const [coin] = txb.splitCoins(txb.gas, [gasFee]);
  txb.transferObjects([coin], suiWallet.account.address);

  let result = suiWallet.signAndExecuteTransactionBlock({
    transactionBlock: txb,
    // chain: "sui:testnet",
  });

  let coins = await client
    .getCoins({ owner: suiWallet.account.address })
    .then((res) => res)
    .catch((error) => console.log(error));

  console.log("coinsss", coins);

  return result;
}
