// reducer.js
import {
  GET_LANDING_PAGE_WALLET_DATA_ACTION,
  GET_LANDING_PAGE_WALLET_DATA_ACTION_SUCCESS,
  GET_LANDING_PAGE_WALLET_DATA_ACTION_FAILURE,
  GET_WALLET_BALANCE_SEED_TOKEN_DATA,
  GET_WALLET_BALANCE_SEED_TOKEN_DATA_SUCCESS,
  GET_WALLET_BALANCE_SEED_TOKEN_DATA_FAILURE,
  RESET_GET_LANDING_PAGE_WALLET_DATA_ACTION,
} from "./actionTypes";

const initialState = {
  loadinglandingPageWalletData: false,
  successlandingPageWalletData: false,
  errorlandingPageWalletData: null,
  landingPageWalletData: [],
  errorlandingPageWalletDataMsg: null,
  //BalanceSeedToken
  loadingBalanceSeedTokenData: false,
  successBalanceSeedTokenData: false,
  errorBalanceSeedTokenData: null,
  balanceSeedTokenData: [],
  errorBalanceSeedTokenDataMsg: null,
};
const LandingPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LANDING_PAGE_WALLET_DATA_ACTION:
      return {
        ...state,
        loadinglandingPageWalletData: true,
        successlandingPageWalletData: false,
        errorlandingPageWalletData: false,
      };
    case GET_LANDING_PAGE_WALLET_DATA_ACTION_SUCCESS:
      return {
        ...state,
        loadinglandingPageWalletData: false,
        successlandingPageWalletData: true,
        errorlandingPageWalletData: false,
        errorlandingPageWalletDataMsg: null,
        landingPageWalletData: action.payload,
      };
    case GET_LANDING_PAGE_WALLET_DATA_ACTION_FAILURE:
      return {
        ...state,
        loadinglandingPageWalletData: false,
        successlandingPageWalletData: false,
        errorlandingPageWalletData: true,
        landingPageWalletData: null,
        errorlandingPageWalletDataMsg: action.payload,
      };
    //BalanceSeedToken
    case GET_WALLET_BALANCE_SEED_TOKEN_DATA:
      return {
        ...state,
        loadingBalanceSeedTokenData: true,
        successBalanceSeedTokenData: false,
        errorBalanceSeedTokenData: false,
      };
    case GET_WALLET_BALANCE_SEED_TOKEN_DATA_SUCCESS:
      return {
        ...state,
        loadingBalanceSeedTokenData: false,
        successBalanceSeedTokenData: true,
        errorBalanceSeedTokenData: false,
        errorBalanceSeedTokenDataMsg: null,
        balanceSeedTokenData: action.payload,
      };
    case GET_WALLET_BALANCE_SEED_TOKEN_DATA_FAILURE:
      return {
        ...state,
        loadingBalanceSeedTokenData: false,
        successBalanceSeedTokenData: false,
        errorBalanceSeedTokenData: true,
        balanceSeedTokenData: null,
        errorBalanceSeedTokenDataMsg: action.payload,
      };
    case RESET_GET_LANDING_PAGE_WALLET_DATA_ACTION:
      return {
        ...state,
        loadinglandingPageWalletData: false,
        successlandingPageWalletData: false,
        errorlandingPageWalletData: null,
        errorlandingPageWalletDataMsg: null,
        landingPageWalletData: null,
      };
    default:
      return state;
  }
};

export default LandingPageReducer;
