import {
  READ_CUSTOM_TASK,
  READ_CUSTOM_TASK_ERROR,
  READ_CUSTOM_TASK_SUCCESS,
  READ_MY_CUSTOM_TASK,
  READ_MY_CUSTOM_TASK_SUCCESS,
  READ_MY_CUSTOM_TASK_ERROR,
  UPDATE_CUSTOM_TASK,
  UPDATE_CUSTOM_TASK_ERROR,
  UPDATE_CUSTOM_TASK_SUCCESS,
  RESET_CUSTOM_TASK_LIST_FLAG,
  RESET_CUSTOM_TASK_UPDATE_FLAG,
} from "./actionTypes";

const initialState = {
  customTaskActionList: null,
  customTaskActionListMsg: null,
  customTaskActionListSuccess: false,
  customTaskActionListError: false,
  customTaskActionListLoading: false,

  myCustomTaskActionList: null,
  myCustomTaskActionListMsg: null,
  myCustomTaskActionListSuccess: false,
  myCustomTaskActionListError: false,
  myCustomTaskActionListLoading: false,

  customTaskActionUpdate: null,
  customTaskActionUpdateMsg: null,
  customTaskActionUpdateSuccess: false,
  customTaskActionUpdateError: false,
  customTaskActionUpdateLoading: false,
};

const customTaskActionReducer = (state = initialState, action) => {
  switch (action.type) {
    //READ_CUSTOM_TASK
    case READ_CUSTOM_TASK:
      state = {
        ...state,
        customTaskActionListSuccess: false,
        customTaskActionListError: false,
        customTaskActionListLoading: true,
      };
      break;
    case READ_CUSTOM_TASK_SUCCESS:
      state = {
        ...state,
        customTaskActionList: action.payload,
        customTaskActionListMsg: null,
        customTaskActionListSuccess: true,
        customTaskActionListError: false,
        customTaskActionListLoading: false,
      };
      break;
    case READ_CUSTOM_TASK_ERROR:
      state = {
        ...state,
        customTaskActionList: null,
        customTaskActionListMsg: action.payload,
        customTaskActionListSuccess: false,
        customTaskActionListError: true,
        customTaskActionListLoading: false,
      };
      break;

    //READ_MY_CUSTOM_TASK
    case READ_MY_CUSTOM_TASK:
      state = {
        ...state,
        myCustomTaskActionListSuccess: false,
        myCustomTaskActionListError: false,
        myCustomTaskActionListLoading: true,
      };
      break;
    case READ_MY_CUSTOM_TASK_SUCCESS:
      state = {
        ...state,
        myCustomTaskActionList: action.payload,
        myCustomTaskActionListMsg: null,
        myCustomTaskActionListSuccess: true,
        myCustomTaskActionListError: false,
        myCustomTaskActionListLoading: false,
      };
      break;
    case READ_MY_CUSTOM_TASK_ERROR:
      state = {
        ...state,
        myCustomTaskActionList: null,
        myCustomTaskActionListMsg: action.payload,
        myCustomTaskActionListSuccess: false,
        myCustomTaskActionListError: true,
        myCustomTaskActionListLoading: false,
      };
      break;

    //UPDATE_CUSTOM_TASK
    case UPDATE_CUSTOM_TASK:
      state = {
        ...state,
        customTaskActionUpdateSuccess: false,
        customTaskActionUpdateError: false,
        customTaskActionUpdateLoading: true,
      };
      break;
    case UPDATE_CUSTOM_TASK_SUCCESS:
      state = {
        ...state,
        customTaskActionUpdate: action.payload,
        customTaskActionUpdateMsg: null,
        customTaskActionUpdateSuccess: true,
        customTaskActionUpdateError: false,
        customTaskActionUpdateLoading: false,
      };
      break;
    case UPDATE_CUSTOM_TASK_ERROR:
      state = {
        ...state,
        customTaskActionUpdate: null,
        customTaskActionUpdateMsg: action.payload,
        customTaskActionUpdateSuccess: false,
        customTaskActionUpdateError: true,
        customTaskActionUpdateLoading: false,
      };
      break;

    //RESET_FLAGS
    case RESET_CUSTOM_TASK_LIST_FLAG:
      state = {
        ...state,
        customTaskActionListMsg: null,
        customTaskActionListSuccess: false,
        customTaskActionListError: false,
        customTaskActionListLoading: false,
      };
      break;
    //RESET_
    case RESET_CUSTOM_TASK_UPDATE_FLAG:
      state = {
        ...state,
        customTaskActionUpdateMsg: null,
        customTaskActionUpdateSuccess: false,
        customTaskActionUpdateError: false,
        customTaskActionUpdateLoading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default customTaskActionReducer;
