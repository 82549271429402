// actions.js
import {
  GET_LANDING_PAGE_WALLET_DATA_ACTION,
  GET_LANDING_PAGE_WALLET_DATA_ACTION_SUCCESS,
  GET_LANDING_PAGE_WALLET_DATA_ACTION_FAILURE,
  GET_WALLET_BALANCE_SEED_TOKEN_DATA,
  GET_WALLET_BALANCE_SEED_TOKEN_DATA_SUCCESS,
  GET_WALLET_BALANCE_SEED_TOKEN_DATA_FAILURE,
  RESET_GET_LANDING_PAGE_WALLET_DATA_ACTION,
} from "./actionTypes";

export const getLandingPageWalletDataAction = (data) => ({
  type: GET_LANDING_PAGE_WALLET_DATA_ACTION,
  payload: data,
});

export const getLandingPageWalletDataActionSuccess = (data) => ({
  type: GET_LANDING_PAGE_WALLET_DATA_ACTION_SUCCESS,
  payload: data,
});

export const getLandingPageWalletDataActionFailure = (error) => ({
  type: GET_LANDING_PAGE_WALLET_DATA_ACTION_FAILURE,
  payload: error,
});

export const getWalletBalanceSeedTokenDataAction = (data) => ({
  type: GET_WALLET_BALANCE_SEED_TOKEN_DATA,
  payload: data,
});

export const getWalletBalanceSeedTokenDataActionSuccess = (data) => ({
  type: GET_WALLET_BALANCE_SEED_TOKEN_DATA_SUCCESS,
  payload: data,
});

export const getWalletBalanceSeedTokenDataActionFailure = (error) => ({
  type: GET_WALLET_BALANCE_SEED_TOKEN_DATA_FAILURE,
  payload: error,
});

export const resetGetlandingPageWalletDataAction = () => {
  return {
    type: RESET_GET_LANDING_PAGE_WALLET_DATA_ACTION,
  };
};
