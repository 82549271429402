import ReactDOM from "react-dom";

function Popup({ children }) {
  if (typeof window !== "undefined") {
    const portalRoot = document.getElementById("All-popups");
    if (portalRoot) {
      return ReactDOM?.createPortal(children, portalRoot);
    }
  }
  return null;
}

export default Popup;
