// reducer.js
import {
  GET_COUNTRIES_ACTION,
  GET_COUNTRIES_ACTION_SUCCESS,
  GET_COUNTRIES_ACTION_ERROR,
  GET_PROVINCES_ACTION,
  GET_PROVINCES_ACTION_SUCCESS,
  GET_PROVINCES_ACTION_ERROR,
  GET_CITIES_ACTION,
  GET_CITIES_ACTION_SUCCESS,
  GET_CITIES_ACTION_ERROR,
  POST_SHIPPING_ADDRESS_ACTION,
  POST_SHIPPING_ADDRESS_ACTION_SUCCESS,
  POST_SHIPPING_ADDRESS_ACTION_ERROR,
  RESET_SHIPPING_ADDRESS_ACTION,
  GET_SHIPPING_ADDRESS_ACTION,
  GET_SHIPPING_ADDRESS_ACTION_SUCCESS,
  GET_SHIPPING_ADDRESS_ACTION_ERROR,
} from "./actionTypes";

const initialState = {
  loadingCountry: false,
  successCountry: false,
  errorCountry: false,
  MessageCountry: null,
  Country: [],

  loadingProvinces: false,
  successProvinces: false,
  errorProvinces: false,
  MessageProvinces: null,
  Provinces: [],

  loadingCities: false,
  successCities: false,
  errorCities: false,
  MessageCities: null,
  Cities: [],

  loadingShippingAddress: false,
  successShippingAddress: false,
  errorShippingAddress: false,
  MessageShippingAddress: null,
  ShippingAddress: [],

  loadingShippingAddressDetails: false,
  successShippingAddressDetails: false,
  errorShippingAddressDetails: false,
  MessageShippingAddressDetails: null,
  ShippingAddressDetails: [],
};
const ShippingAddressReducer = (state = initialState, action) => {
  switch (action.type) {
    //GET_COUNTRIES_ACTION
    case GET_COUNTRIES_ACTION:
      return {
        ...state,
        loadingCountry: true,
        successCountry: false,
        errorCountry: false,
      };
    case GET_COUNTRIES_ACTION_SUCCESS:
      return {
        ...state,
        loadingCountry: false,
        successCountry: true,
        errorCountry: false,
        MessageCountry: null,
        Country: action.payload,
      };
    case GET_COUNTRIES_ACTION_ERROR:
      return {
        ...state,
        loadingCountry: false,
        successCountry: false,
        errorCountry: true,
        MessageCountry: action.payload,
        Country: [],
      };

    //GET_PROVINCES_ACTION
    case GET_PROVINCES_ACTION:
      return {
        ...state,
        loadingProvinces: true,
        successProvinces: false,
        errorProvinces: false,
      };
    case GET_PROVINCES_ACTION_SUCCESS:
      return {
        ...state,
        loadingProvinces: false,
        successProvinces: true,
        errorProvinces: false,
        MessageProvinces: null,
        Provinces: action.payload,
      };
    case GET_PROVINCES_ACTION_ERROR:
      return {
        ...state,
        loadingProvinces: false,
        successProvinces: false,
        errorProvinces: true,
        MessageProvinces: action.payload,
        Provinces: [],
      };

    //GET_CITIES_ACTION
    case GET_CITIES_ACTION:
      return {
        ...state,
        loadingCities: true,
        successCities: false,
        errorCities: false,
      };
    case GET_CITIES_ACTION_SUCCESS:
      return {
        ...state,
        loadingCities: false,
        successCities: true,
        errorCities: false,
        MessageCities: null,
        Cities: action.payload,
      };
    case GET_CITIES_ACTION_ERROR:
      return {
        ...state,
        loadingCities: false,
        successCities: false,
        errorCities: true,
        MessageCities: action.payload,
        Cities: [],
      };

    //POST_SHIPPING_ADDRESS_ACTION
    case POST_SHIPPING_ADDRESS_ACTION:
      return {
        ...state,
        loadingShippingAddress: true,
        successShippingAddress: false,
        errorShippingAddress: false,
      };
    case POST_SHIPPING_ADDRESS_ACTION_SUCCESS:
      return {
        ...state,
        loadingShippingAddress: false,
        successShippingAddress: true,
        errorShippingAddress: false,
        MessageShippingAddress: null,
        ShippingAddress: action.payload,
      };
    case POST_SHIPPING_ADDRESS_ACTION_ERROR:
      return {
        ...state,
        loadingShippingAddress: false,
        successShippingAddress: false,
        errorShippingAddress: true,
        MessageShippingAddress: null,
        ShippingAddress: action.payload,
      };

    //GET_SHIPPING_ADDRESS_ACTION
    case GET_SHIPPING_ADDRESS_ACTION:
      return {
        ...state,
        loadingShippingAddressDetails: true,
        successShippingAddressDetails: false,
        errorShippingAddressDetails: false,
      };
    case GET_SHIPPING_ADDRESS_ACTION_SUCCESS:
      return {
        ...state,
        loadingShippingAddressDetails: false,
        successShippingAddressDetails: true,
        errorShippingAddressDetails: false,
        MessageShippingAddressDetails: null,
        ShippingAddressDetails: action.payload,
      };
    case GET_SHIPPING_ADDRESS_ACTION_ERROR:
      return {
        ...state,
        loadingShippingAddressDetails: false,
        successShippingAddressDetails: false,
        errorShippingAddressDetails: true,
        MessageShippingAddressDetails: null,
        ShippingAddressDetails: action.payload,
      };

    //RESET_SHIPPING_ADDRESS_ACTION
    case RESET_SHIPPING_ADDRESS_ACTION:
      return {
        ...state,
        loadingShippingAddress: false,
        successShippingAddress: false,
        errorShippingAddress: false,
        MessageShippingAddress: null,

        loadingShippingAddressDetails: false,
        successShippingAddressDetails: false,
        errorShippingAddressDetails: false,
        MessageShippingAddressDetails: null,
      };
    default:
      return state;
  }
};

export default ShippingAddressReducer;
