// actions.js
import {
  SEND_LAST_NOTIFICATION_REQUEST,
  SEND_LAST_NOTIFICATION_SUCCESS,
  SEND_LAST_NOTIFICATION_FAILURE,
  RESET_SEND_LAST_NOTIFICATION,
} from "./actionTypes";

export const getLastNotificationRequest = (requestData) => ({
  type: SEND_LAST_NOTIFICATION_REQUEST,
  payload: requestData,
});

export const getLastNotificationSuccess = (data) => ({
  type: SEND_LAST_NOTIFICATION_SUCCESS,
  payload: data,
});

export const getLastNotificationFailure = (error) => ({
  type: SEND_LAST_NOTIFICATION_FAILURE,
  payload: error,
});

export const resetLastNotification = (error) => ({
  type: RESET_SEND_LAST_NOTIFICATION,
});
