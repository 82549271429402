import { all, call, fork, put, takeLatest } from "redux-saga/effects";

import { getAllBalanceWalletData } from "@/config/helpers/backend_helper";
import { GET_ALL_WALLET_BALANCE } from "./actionTypes";
import {
  getAllWalletBalanceActionError,
  getAllWalletBalanceActionSuccess,
} from "./actions";

function* fetchWalletBalances({ payload: { user } }) {
  try {
    const response = yield call(getAllBalanceWalletData, user);
    // console.log("fetchmyProfile", response);
    yield put(getAllWalletBalanceActionSuccess(response));
  } catch (error) {
    yield put(getAllWalletBalanceActionError(error));
  }
}

export function* watchAllWalletBalances() {
  // yield takeEvery(EDIT_PROFILE, editProfile);
  yield takeLatest(GET_ALL_WALLET_BALANCE, fetchWalletBalances);
}

function* WalletBalancesSaga() {
  yield all([fork(watchAllWalletBalances)]);
}

export default WalletBalancesSaga;
