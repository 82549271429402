// saga.js
import {
  getlandingPageWalletData,
  getUserBalanceWalletData,
} from "@/config/helpers/backend_helper";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_LANDING_PAGE_WALLET_DATA_ACTION,
  GET_WALLET_BALANCE_SEED_TOKEN_DATA,
} from "./actionTypes";
import {
  getLandingPageWalletDataActionFailure,
  getLandingPageWalletDataActionSuccess,
  getWalletBalanceSeedTokenDataActionFailure,
  getWalletBalanceSeedTokenDataActionSuccess,
} from "./action";

function* fetchtlandingPageWalletData(action) {
  try {
    const response = yield call(getlandingPageWalletData, action.payload);
    yield put(getLandingPageWalletDataActionSuccess(response));
  } catch (error) {
    yield put(
      getLandingPageWalletDataActionFailure(
        error.response.data.Errors[0].Message
      )
    );
  }
}

function* fetchtBalanceSeedTokenWalletData(action) {
  try {
    const response = yield call(getUserBalanceWalletData, action.payload);
    yield put(getWalletBalanceSeedTokenDataActionSuccess(response));
  } catch (error) {
    yield put(
      getWalletBalanceSeedTokenDataActionFailure(
        error.response.data.Errors[0].Message
      )
    );
  }
}

function* LandingPageSaga() {
  yield takeLatest(
    GET_LANDING_PAGE_WALLET_DATA_ACTION,
    fetchtlandingPageWalletData
  );
  yield takeLatest(
    GET_WALLET_BALANCE_SEED_TOKEN_DATA,
    fetchtBalanceSeedTokenWalletData
  );
}

export default LandingPageSaga;
