import {
  postCustomTaskList,
  postMyCustomTaskList,
  postUpdateCustomTask,
} from "@/config/helpers/backend_helper";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  listCustomTaskError,
  listCustomTaskSuccess,
  listMyCustomTaskError,
  listMyCustomTaskSuccess,
  updateCustomTaskError,
  updateCustomTaskSuccess,
} from "./actions";
import {
  READ_CUSTOM_TASK,
  READ_MY_CUSTOM_TASK,
  UPDATE_CUSTOM_TASK,
} from "./actionTypes";

function* listCustomTask({ payload: CustomTask }) {
  try {
    const response = yield call(postCustomTaskList, CustomTask);
    yield put(listCustomTaskSuccess(response));
  } catch (error) {
    yield put(listCustomTaskError(error));
  }
}

function* listMyCustomTask({ payload: CustomTask }) {
  try {
    const response = yield call(postMyCustomTaskList, CustomTask);
    yield put(listMyCustomTaskSuccess(response));
  } catch (error) {
    yield put(listMyCustomTaskError(error));
  }
}

function* updateCustomTask({ payload: { update } }) {
  try {
    const response = yield call(postUpdateCustomTask, update);
    yield put(updateCustomTaskSuccess(response));
  } catch (error) {
    yield put(updateCustomTaskError(error));
  }
}

export function* watchCustomTask() {
  yield takeEvery(READ_CUSTOM_TASK, listCustomTask);
  yield takeEvery(READ_MY_CUSTOM_TASK, listMyCustomTask);
  yield takeEvery(UPDATE_CUSTOM_TASK, updateCustomTask);
}

function* CustomTaskSaga() {
  yield all([fork(watchCustomTask)]);
}

export default CustomTaskSaga;
