// saga.js
import {
  getHomeTopCollectors,
  postHomeAllCategories,
} from "@/config/helpers/backend_helper";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  seedCategoriesDataError,
  seedCategoriesDataSuccess,
  topCategoriesDataError,
  topCategoriesDataSuccess,
} from "./actions";
import { ALL_CATEGORIES, TOP_CATEGORIES } from "./actionTypes";

function* fetchListCategoriesData(action) {
  try {
    const response = yield call(postHomeAllCategories, action.payload);

    yield put(seedCategoriesDataSuccess(response));
  } catch (error) {
    yield put(seedCategoriesDataError(error));
  }
}

function* fetchTopCategoriesData(action) {
  try {
    const response = yield call(postHomeAllCategories, action.payload);

    yield put(topCategoriesDataSuccess(response));
  } catch (error) {
    yield put(topCategoriesDataError(error));
  }
}

function* SeedCategoriesSaga() {
  yield takeLatest(ALL_CATEGORIES, fetchListCategoriesData);
  yield takeLatest(TOP_CATEGORIES, fetchTopCategoriesData);
}

export default SeedCategoriesSaga;
