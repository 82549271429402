//GET_COUNTRIES_ACTION
export const GET_COUNTRIES_ACTION = "GET_COUNTRIES_ACTION";
export const GET_COUNTRIES_ACTION_SUCCESS = "GET_COUNTRIES_ACTION_SUCCESS";
export const GET_COUNTRIES_ACTION_ERROR = "GET_COUNTRIES_ACTION_ERROR";

//GET_PROVINCES_ACTION
export const GET_PROVINCES_ACTION = "GET_PROVINCES_ACTION";
export const GET_PROVINCES_ACTION_SUCCESS = "GET_PROVINCES_ACTION_SUCCESS";
export const GET_PROVINCES_ACTION_ERROR = "GET_PROVINCES_ACTION_ERROR";

//GET_CITIES_ACTION
export const GET_CITIES_ACTION = "GET_CITIES_ACTION";
export const GET_CITIES_ACTION_SUCCESS = "GET_CITIES_ACTION_SUCCESS";
export const GET_CITIES_ACTION_ERROR = "GET_CITIES_ACTION_ERROR";

//POST_SHIPPING_ADDRESS_ACTION
export const POST_SHIPPING_ADDRESS_ACTION = "POST_SHIPPING_ADDRESS_ACTION";
export const POST_SHIPPING_ADDRESS_ACTION_SUCCESS =
  "POST_SHIPPING_ADDRESS_ACTION_SUCCESS";
export const POST_SHIPPING_ADDRESS_ACTION_ERROR =
  "POST_SHIPPING_ADDRESS_ACTION_ERROR";

//GET_SHIPPING_ADDRESS_ACTION
export const GET_SHIPPING_ADDRESS_ACTION = "GET_SHIPPING_ADDRESS_ACTION";
export const GET_SHIPPING_ADDRESS_ACTION_SUCCESS =
  "GET_SHIPPING_ADDRESS_ACTION_SUCCESS";
export const GET_SHIPPING_ADDRESS_ACTION_ERROR =
  "GET_SHIPPING_ADDRESS_ACTION_ERROR";

//RESET_SHIPPING_ADDRESS_ACTION
export const RESET_SHIPPING_ADDRESS_ACTION = "RESET_SHIPPING_ADDRESS_ACTION";
