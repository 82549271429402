import {
  READ_CUSTOM_TASK,
  READ_CUSTOM_TASK_ERROR,
  READ_CUSTOM_TASK_SUCCESS,
  UPDATE_CUSTOM_TASK,
  UPDATE_CUSTOM_TASK_ERROR,
  UPDATE_CUSTOM_TASK_SUCCESS,
  READ_MY_CUSTOM_TASK,
  READ_MY_CUSTOM_TASK_ERROR,
  READ_MY_CUSTOM_TASK_SUCCESS,
  RESET_CUSTOM_TASK_LIST_FLAG,
  RESET_CUSTOM_TASK_UPDATE_FLAG,
} from "./actionTypes";

//read
export const listCustomTask = (CustomTask) => {
  return {
    type: READ_CUSTOM_TASK,
    payload: CustomTask,
  };
};

export const listCustomTaskSuccess = (CustomTask) => {
  return {
    type: READ_CUSTOM_TASK_SUCCESS,
    payload: CustomTask,
  };
};

export const listCustomTaskError = (CustomTask) => {
  return {
    type: READ_CUSTOM_TASK_ERROR,
    payload: CustomTask,
  };
};

//read-my-task
export const listMyCustomTask = (CustomTask) => {
  return {
    type: READ_MY_CUSTOM_TASK,
    payload: CustomTask,
  };
};

export const listMyCustomTaskSuccess = (CustomTask) => {
  return {
    type: READ_MY_CUSTOM_TASK_SUCCESS,
    payload: CustomTask,
  };
};

export const listMyCustomTaskError = (CustomTask) => {
  return {
    type: READ_MY_CUSTOM_TASK_ERROR,
    payload: CustomTask,
  };
};

//update
export const updateCustomTask = (update) => {
  return {
    type: UPDATE_CUSTOM_TASK,
    payload: { update },
  };
};

export const updateCustomTaskSuccess = (update) => {
  return {
    type: UPDATE_CUSTOM_TASK_SUCCESS,
    payload: update,
  };
};

export const updateCustomTaskError = (update) => {
  return {
    type: UPDATE_CUSTOM_TASK_ERROR,
    payload: update,
  };
};

export const resetCustomTaskFlag = () => {
  return {
    type: RESET_CUSTOM_TASK_LIST_FLAG,
  };
};

export const resetCustomTaskUpdateFlag = () => {
  return {
    type: RESET_CUSTOM_TASK_UPDATE_FLAG,
  };
};
