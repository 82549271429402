import {
  TUTORIALS_LIST,
  TUTORIALS_LIST_SUCCESS,
  TUTORIALS_LIST_ERROR,
  TUTORIALS_INCREASE_VIEW_COUNT,
  TUTORIALS_INCREASE_VIEW_COUNT_SUCCESS,
  TUTORIALS_INCREASE_VIEW_COUNT_ERROR,
  RESET_INCREASE_VIEW_COUNT,
  TUTORIALS_LIST2,
  TUTORIALS_LIST_SUCCESS2,
  TUTORIALS_LIST_ERROR2,
  RESET_TUTORIALS_LIST2,
} from "./actionTypes";

const initialState = {
  errorMsgTutorials: "",
  loadingTutorials: false,
  errorTutorials: false,
  successTutorials: null,
  data: null,

  increaseSuccess: false,
  increaseError: false,
  increaseData: null,
  increaseLoading: false,

  loadingTutorials2: false,
  errorTutorials2: false,
  successTutorials2: false,
  errorMsgTutorials2: null,
  data2: null,
};

const Tutorials = (state = initialState, action) => {
  switch (action.type) {
    case TUTORIALS_LIST:
      state = {
        ...state,
        loadingTutorials: true,
        errorTutorials: false,
        successTutorials: false,
        errorMsgTutorials: null,
      };
      break;
    case TUTORIALS_LIST_SUCCESS:
      state = {
        ...state,
        loadingTutorials: false,
        errorTutorials: false,
        successTutorials: true,
        data: action.payload,
        errorMsgTutorials: null,
      };
      break;
    case TUTORIALS_LIST_ERROR:
      state = {
        ...state,
        errorMsgTutorials: action.payload,
        loadingTutorials: false,
        errorTutorials: true,
        successTutorials: false,
      };
      break;

    case TUTORIALS_LIST2:
      state = {
        ...state,
        loadingTutorials2: true,
        errorTutorials2: false,
        successTutorials2: false,
        errorMsgTutorials2: null,
      };
      break;
    case TUTORIALS_LIST_SUCCESS2:
      state = {
        ...state,
        loadingTutorials2: false,
        errorTutorials2: false,
        successTutorials2: true,
        data2: action.payload,
        errorMsgTutorials2: null,
      };
      break;
    case TUTORIALS_LIST_ERROR2:
      state = {
        ...state,
        errorMsgTutorials2: action.payload,
        loadingTutorials2: false,
        errorTutorials2: true,
        successTutorials2: false,
      };
      break;
    case RESET_TUTORIALS_LIST2:
      state = {
        ...state,
        loadingTutorials2: false,
        errorTutorials2: false,
        successTutorials2: false,
        data2: null,
        errorMsgTutorials2: null,
      };
      break;

    case TUTORIALS_INCREASE_VIEW_COUNT:
      // const { tutorialId } = action.payload;
      state = {
        ...state,
        increaseLoading: true,
        increaseError: false,
        increaseSuccess: false,
      };
      break;
    case TUTORIALS_INCREASE_VIEW_COUNT_SUCCESS:
      state = {
        ...state,
        increaseSuccess: true,
        increaseLoading: false,
        increaseData: action.payload,
      };
      break;
    case TUTORIALS_INCREASE_VIEW_COUNT_ERROR:
      state = {
        ...state,
        increaseError: true,
        successTutorials: false,
      };
      break;
    case RESET_INCREASE_VIEW_COUNT:
      state = {
        increaseSuccess: false,
        increaseError: false,
        increaseData: null,
        increaseLoading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Tutorials;
