// saga.js
import { takeLatest, call, put } from "redux-saga/effects";
import {
  SEND_NOTIFICATION_REQUEST,
  SEND_NOTIFICATION_SUCCESS,
  SEND_NOTIFICATION_FAILURE,
} from "./actionTypes";
import { getNotificationSuccess, getNotificationFailure } from "./action";
import { getAllNotificationList } from "@/config/helpers/backend_helper";

function* fetchNotification(action) {
  try {
    const response = yield call(getAllNotificationList, action.payload);
    // console.log(response);
    yield put(getNotificationSuccess(response));
  } catch (error) {
    yield put(getNotificationFailure(error));
  }
}

function* notificationSaga() {
  yield takeLatest(SEND_NOTIFICATION_REQUEST, fetchNotification);
}

export default notificationSaga;
