import { combineReducers } from "redux";

//setting
import GetEnums from "./mains/enums/reducer";
import GetSettings from "./mains/settings/reducer";
import GetSettingsCategories from "./mains/categories/reducer";

import Login from "./auth/login/reducer";
import Profile from "./auth/profile/reducer";
import Layout from "./layouts/reducer";
import NewsLetter from "./newsLetter/reducer";

//random
import Randomuser from "./auth/randomusername/reducer";

//followMember
import followtoggle from "./auth/followmember/reducer";

//coverprofile
import coverprofile from "./auth/coverProfile/reducer";
import editGallaryCover from "./auth/editGallary/reducer";
import MyProfile from "./auth/myprofile/reducer";

//nfts
import DeleteNft from "./nfts/deleteNft/reducer";
import NftDetail from "./nfts/details/reducer";
import DownloadCertificate from "./nfts/downloadCertificate/reducer";
import EditNft from "./nfts/editNft/reducer";
import Explore from "./nfts/explore/reducer";
import GetNftHistory from "./nfts/historyNft/reducer";
import Nsfw from "./nfts/nsfw/reducer";
import OrderConfirmation from "./nfts/orderConfirmation/reducer";
import RatingNft from "./nfts/ratingNft/reducer";
import ReportNft from "./nfts/reportNft/reducer";
import UploadNft from "./nfts/upload/reducer";

//Home
import AnnouncementNews from "./home/announcementNews/reducer";
import Communities from "./home/communities/reducer";
import ExclusiveCollections from "./home/exclusiveCollections/reducer";
import FeaturedArtists from "./home/featuredArtists/reducer";
import HeroSection from "./home/heroSection/reducer";
import HotBids from "./home/hotBids/reducer";
import HotCollections from "./home/hotCollections/reducer";
import Museum from "./home/museum/reducer";
import NewListing from "./home/newListing/reducer";
import RecentlySold from "./home/recentlySold/reducer";
import Testimonial from "./home/testimonial/reducer";
import TopArtists from "./home/topArtist/reducer";
import TopCollectors from "./home/topCollectors/reducer";
import TopCurators from "./home/topCurators/reducer";
import TopPhotographers from "./home/topPhotographers/reducer";
import TrendingArtworks from "./home/trendingArtworks/reducer";
import NotificationFilter from "./notifications/filter/reducer";
import LastNotificationFilter from "./notifications/lastNotifactions/reducer";
import MarkAllRead from "./notifications/markAllRead/reducer";
import NotificationSettings from "./notifications/setting/reducer";
import HomeMarketPlaceInfo from "./home/marketPlaceInfo/reducer";
import Events from "./home/events/reducer";
import SeedCategories from "./home/seedCategories/reducer";

//Account
import AssetPortfolio from "./auth/assetPortfolio/reducer";
import CheckUserName from "./auth/checkAvailableUserName/reducer";
import Follower from "./auth/followers/reducer";
import Following from "./auth/following/reducer";
import Referral from "./auth/referrals/reducer";
import ReportUser from "./auth/reportUser/reducer";
import ToggleNftLike from "./auth/toggleFavorites/reducer";
import UserCollection from "./auth/userCollection/reducer";
import VerificationForm from "./auth/verificationForm/reducer";
import WalletBalance from "./auth/walletBalance/reducer";
//Artist
import ReferralDashboard from "./auth/referralDashboard/reducer";
import Inspire from "./nfts/inspire/reducer";
//collections
import UserCollectionNft from "./collections/reducer";
//Nftcard
import AllNftCard from "./nftCards/all/reducer";
import ArchiveNftCard from "./nftCards/archive/reducer";
import CollaborationNftCard from "./nftCards/collaboration/reducer";
import CollectedNftCard from "./nftCards/collected/reducer";
import AccountCollectionNfts from "./nftCards/collections/reducer";
import CreatedNftCard from "./nftCards/created/reducer";
import FavoritesNftCard from "./nftCards/favorites/reducer";
import ManageMyBidsNftCard from "./nftCards/manageMyBids/reducer";
import MuseumNftCard from "./nftCards/museum/reducer";
import SimillarArtworks from "./nftCards/simillarArtworks/reducer";
import MyTransactions from "./nftCards/transactions/reducer";
import Web3Action from "./nfts/web3Actions/reducer";

//shippingAddress
import ShippingAddressReducer from "./nfts/shippingAddress/reducer";

// Tutorials
import Tutorials from "./tutorials/reducer";

// Museum
import MuseumAuction from "./museum/Auction/reducer";
import MuseumCalendar from "./museum/Calendar/reducer";
import MuseumCurrentWeek from "./museum/CurrentWeek/reducer";
import MuseumReleased from "./museum/Released/reducer";
import MuseumUpcoming from "./museum/Upcoming/reducer";
import MuseumWelcome from "./museum/Welcome/reducer";

//suiWallet
import SuiWalletAccount from "./auth/suiWallet/reducer";

//EthWallet
import EthWalletAccount from "./auth/EthWallet/reducer";

//GeneralSearch
import GeneralSearchReducer from "./generalSearch/reducer";

//LandingPage
import LandingPageReducer from "./landingPage/reducer";

//TwitterFarm
import TwitterFarm from "./twitterFarm/reducer";
import TwitterPointsReducer from "./twitter/Points/reducer";
import TwitterWithdrawalsReducer from "./twitter/Withdrawals/reducer";
import TwitterStakesReducer from "./twitter/Stakes/reducer";

//twitter-verify
import VerifyTwitter from "./twitter/verifyTwitter/reducer";

//custom task
import customTaskActionReducer from "./twitter/CustomTask/reducer";

const rootReducer = combineReducers({
  GetSettings,
  GetSettingsCategories,
  GetEnums,
  Login,
  MyProfile,
  Profile,
  Layout,
  NewsLetter,
  HeroSection,
  TrendingArtworks,
  FeaturedArtists,
  TopCurators,
  TopPhotographers,
  Communities,
  ExclusiveCollections,
  TopCollectors,
  HotCollections,
  RecentlySold,
  HotBids,
  Testimonial,
  AnnouncementNews,
  coverprofile,
  followtoggle,
  Museum,
  MarkAllRead,
  //nfts
  Explore,
  Inspire,
  NftDetail,
  GetNftHistory,
  editGallaryCover,
  NotificationSettings,
  NotificationFilter,
  LastNotificationFilter,
  ReferralDashboard,
  Randomuser,
  UploadNft,
  NewListing,
  DownloadCertificate,
  ReportNft,
  DeleteNft,
  EditNft,
  Nsfw,
  RatingNft,
  //accounts
  AssetPortfolio,
  VerificationForm,
  WalletBalance,
  UserCollection,
  UserCollectionNft,
  SimillarArtworks,
  AllNftCard,
  CreatedNftCard,
  MuseumNftCard,
  CollectedNftCard,
  CollaborationNftCard,
  FavoritesNftCard,
  ToggleNftLike,
  ArchiveNftCard,
  ManageMyBidsNftCard,
  AccountCollectionNfts,
  OrderConfirmation,
  Web3Action,
  Tutorials,
  MuseumReleased,
  MuseumWelcome,
  MuseumCurrentWeek,
  MuseumAuction,
  MuseumCalendar,
  MuseumUpcoming,
  TopArtists,
  MyTransactions,
  Following,
  Follower,
  Referral,
  ReportUser,
  CheckUserName,
  SuiWalletAccount,
  EthWalletAccount,
  GeneralSearchReducer,
  HomeMarketPlaceInfo,
  Events,
  SeedCategories,
  LandingPageReducer,

  //shippingAddress
  ShippingAddressReducer,

  //TwitterFarm
  TwitterFarm,
  TwitterPointsReducer,
  TwitterWithdrawalsReducer,
  TwitterStakesReducer,

  //twitter-verify
  VerifyTwitter,

  //custom task
  customTaskActionReducer,
});

export default rootReducer;
