import {
  TOGGLE_FAVORITES,
  TOGGLE_FAVORITES_SUCCESS,
  TOGGLE_FAVORITES_ERROR,
  TOGGLE_FAVORITES_COLLECTIONS,
  TOGGLE_FAVORITES_COLLECTIONS_SUCCESS,
  TOGGLE_FAVORITES_COLLECTIONS_ERROR,
} from "./actionTypes";

const initialState = {
  errorMsgToggleLike: "",
  loadingToggleLike: false,
  errorToggleLike: false,
  successToggleLike: null,
  idToggleLike: [],
  isLiked: [],

  errorMsg: "",
  loading: false,
  error: false,
  success: false,
  idLike: [],
  isLikedCollection: [],
};

const ToggleNftLike = (state = initialState, action) => {
  switch (action.type) {
    //TOGGLE_FAVORITES
    case TOGGLE_FAVORITES:
      state = {
        ...state,
        loadingToggleLike: true,
        errorToggleLike: false,
        successToggleLike: false,
        errorMsgToggleLike: null,
        idToggleLike: action.payload,
      };
      break;
    case TOGGLE_FAVORITES_SUCCESS:
      state = {
        ...state,
        loadingToggleLike: false,
        errorToggleLike: false,
        successToggleLike: true,
        isLiked: action.payload,
        errorMsgToggleLike: null,
      };
      break;
    case TOGGLE_FAVORITES_ERROR:
      state = {
        ...state,
        errorMsgToggleLike: action.payload,
        loadingToggleLike: false,
        errorToggleLike: true,
        successToggleLike: false,
      };
      break;

    //TOGGLE_FAVORITES_COLLECTIONS
    case TOGGLE_FAVORITES_COLLECTIONS:
      state = {
        ...state,
        loading: true,
        error: false,
        success: false,
        errorMsg: null,
        idLike: action.payload,
      };
      break;
    case TOGGLE_FAVORITES_COLLECTIONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
        success: true,
        isLikedCollection: action.payload,
        errorMsg: null,
      };
      break;
    case TOGGLE_FAVORITES_COLLECTIONS_ERROR:
      state = {
        ...state,
        errorMsg: action.payload,
        loading: false,
        error: true,
        success: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ToggleNftLike;
