import React, { useState } from "react";
import Modal from "./modal";
import seed_logo_dark from "@/public/logo/seed-logo-dark.png";
import seed_logo_light from "@/public/logo/seed-logo-light-m.webp";
import bnbicon from "@/assets/images/chains/img-bnb-chain.svg";
import ethicon from "@/assets/images/chains/img-eth-chain.svg";
import polygonicon from "@/assets/images/chains/img-polygon-chain.svg";
import suiChain from "@/assets/images/chains/img-sui-chain.webp";
import seedbadge from "@/assets/images/seed-badge.svg";
import Image from "next/image";
import { chainIdd, getChainName } from "@/config/helpers/web3Utiles";
import { chainList } from "../constants/layout";
import { ThreeDots } from "react-loader-spinner";

function W3A_ConfirmTransactionModal({
  transactionData,
  openModal,
  onCloseModal,
  fromAddress,
  destinationAddress,
  actionName,
  chain,
  amount = null,
  messageTitle = null,
  messageText = "",
  onConfirm,
  onReject,
}) {
  const [loading, setLoading] = useState(false);
  const handleChainIcon = () => {
    if (chain) {
      if (chain === chainList.BSCMainnet || chain === chainList.BSCTestnet) {
        return bnbicon;
      } else if (
        chain === chainList.EthereumMainnet ||
        chain === chainList.EthereumTestnet
      ) {
        return ethicon;
      } else if (
        chain === chainList.PolygonMainnet ||
        chain === chainList.PolygonTestnet
      ) {
        return polygonicon;
      } else if (
        chain === chainList.SuiChainMainnet ||
        chain === chainList.SuiChainTestnet
      ) {
        return suiChain;
      } else if (chain === chainList.SeedToken) {
        return seedbadge;
      }
    } else {
      return bnbicon;
    }
  };

  const handleClose = (e) => {
    e.preventDefault(e);
    if (document.querySelector(`.seed-invoice`)) {
      var invoice_modal = document.querySelectorAll(`.seed-invoice`);
      invoice_modal.forEach((item) => item.remove());
    }

    document.querySelector("body").classList.remove("ovaerlay_body");
  };

  return (
    <>
      {openModal && (
        <Modal
          isOpen={openModal}
          onClose={onCloseModal}
          modalstyle={"invoice__card scale-in-center-enter"}
          modaloverlay={"modal-overlay seed-invoice"}
          noClose={true}
          limitClose={true}
        >
          <div className="invoice__logo">
            <Image
              src={seed_logo_dark}
              alt="seed-logo-light"
              className="some-icon"
              width={170}
              height={52}
            />
            <Image
              src={seed_logo_light}
              alt="seed-logo-dark"
              className="some-icon-dark"
              width={170}
              height={52}
            />
          </div>
          {chain ? (
            <div className="invoice__chain">
              <div className="chain_icon">
                <Image
                  src={handleChainIcon()}
                  width={20}
                  height={20}
                  alt="chain-icon"
                />
                <span>{getChainName(chain)}</span>
              </div>
            </div>
          ) : null}

          <div className="invoice__origin">
            {process.env.NEXT_PUBLIC_ENV_PROTOCOL}://
            {process.env.NEXT_PUBLIC_URL}
          </div>

          <div className="invoice__web2_action">
            <span className="dest_address">
              {`${destinationAddress?.slice(
                0,
                14
              )}...${destinationAddress?.slice(39)}`}
            </span>{" "}
            : {actionName?.toUpperCase()}
          </div>
          {amount !== null ? (
            <div className="invoice__amount">
              {amount} {getChainName(chain)}
            </div>
          ) : null}

          {messageTitle !== null ? (
            <div className="invoice__message_title">{messageTitle}</div>
          ) : null}

          {messageText.length > 0 || messageText !== null ? (
            <div className="invoice__message_text">{messageText}</div>
          ) : null}

          <div className="invoice__table">
            <div className="invoice__row">
              <div className="row_title">From</div>
              <div className="row_value">
                {`${fromAddress?.slice(0, 14)}...${fromAddress?.slice(39)}`}
              </div>
            </div>
            <div className="invoice__row">
              <div className="row_title">To</div>
              <div className="row_value">
                {`${destinationAddress?.slice(
                  0,
                  14
                )}...${destinationAddress?.slice(39)}`}
              </div>
            </div>
          </div>

          <div className="invoice__actions">
            <button
              disabled={loading}
              className="invoice__action rounded secondary"
              onClick={(e) => {
                e.preventDefault();
                setLoading(true);
                onReject();
                handleClose(e);
              }}
            >
              Reject
            </button>

            <button
              className="invoice__action rounded primary"
              disabled={loading}
              onClick={(e) => {
                e.preventDefault();
                setLoading(true);
                onConfirm();
                setTimeout(() => {
                  handleClose(e);
                }, 2000);
              }}
            >
              {loading ? (
                <ThreeDots
                  height="20"
                  width="20"
                  radius="9"
                  color="gray"
                  //   ariaLabel="three-dots-loading"
                  wrapperStyle={{
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                  //   wrapperClassName=""
                  visible={true}
                />
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        </Modal>
      )}
    </>
  );
}

export default W3A_ConfirmTransactionModal;
