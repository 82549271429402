import {
  ALL_CATEGORIES,
  ALL_CATEGORIES_SUCCESS,
  ALL_CATEGORIES_ERROR,
  RESET_HOME_ALL_CATEGORIES,
  //TOP
  TOP_CATEGORIES,
  TOP_CATEGORIES_SUCCESS,
  TOP_CATEGORIES_ERROR,
} from "./actionTypes";

const initialState = {
  errorMsgSeedCategories: "",
  loadingSeedCategories: false,
  errorSeedCategories: false,
  successSeedCategories: null,
  seedCategories: null,
  //TOP
  errorMsgTopCategories: "",
  loadingTopCategories: false,
  errorTopCategories: false,
  successTopCategories: null,
  topCategories: null,
};

const SeedCategories = (state = initialState, action) => {
  switch (action.type) {
    case ALL_CATEGORIES:
      return {
        ...state,
        loadingSeedCategories: true,
        errorSeedCategories: false,
        successSeedCategories: false,
        errorMsgSeedCategories: null,
      };
    case ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        loadingSeedCategories: false,
        errorSeedCategories: false,
        successSeedCategories: true,
        seedCategories: action.payload,
        errorMsgSeedCategories: null,
      };
    case ALL_CATEGORIES_ERROR:
      return {
        ...state,
        errorMsgSeedCategories: action.payload,
        loadingSeedCategories: false,
        errorSeedCategories: true,
        successSeedCategories: false,
      };
    //TOP
    case TOP_CATEGORIES:
      return {
        ...state,
        loadingTopCategories: true,
        errorTopCategories: false,
        successTopCategories: false,
        errorMsgTopCategories: null,
      };
    case TOP_CATEGORIES_SUCCESS:
      return {
        ...state,
        loadingTopCategories: false,
        errorTopCategories: false,
        successTopCategories: true,
        topCategories: action.payload,
        errorMsgTopCategories: null,
      };
    case TOP_CATEGORIES_ERROR:
      return {
        ...state,
        errorMsgTopCategories: action.payload,
        loadingTopCategories: false,
        errorTopCategories: true,
        successTopCategories: false,
      };
    case RESET_HOME_ALL_CATEGORIES:
      return {
        ...state,
        errorMsgSeedCategories: null,
        loadingSeedCategories: false,
        errorSeedCategories: false,
        successSeedCategories: null,
        seedCategories: null,
      };
    default:
      return state;
  }
};

export default SeedCategories;
