import {
  UPLOAD_NFT,
  UPLOAD_NFT_SUCCESS,
  UPLOAD_NFT_ERROR,
  RESET_UPLOAD_NFT,
  GENERATE_DESCRIPTION,
  GENERATE_DESCRIPTION_SUCCESS,
  GENERATE_DESCRIPTION_ERROR,
  SHORTEREN_DESCRIPTION,
  SHORTEREN_DESCRIPTION_SUCCESS,
  SHORTEREN_DESCRIPTION_ERROR,
  GET_USER_REFERRAL_PROMOTION,
  GET_USER_REFERRAL_PROMOTION_SUCCESS,
  GET_USER_REFERRAL_PROMOTION_ERROR,
  RESET_USER_REFERRAL_PROMOTION,
  NFTS_CHECK_NAME,
  NFTS_CHECK_NAME_SUCCESS,
  NFTS_CHECK_NAME_ERROR,
  RESET_NFTS_CHECK_NAME,
} from "./actionTypes";

const initialState = {
  errorMsgUploadNft: null,
  loadingUploadNft: false,
  errorUploadNft: false,
  successUploadNft: null,
  form: [],

  errorMsgNftdescription: "",
  loadingNftdescription: false,
  errorNftdescription: false,
  successNftdescription: null,
  name: [],

  errorMsgdescriptionShorteren: "",
  loadingdescriptionShorteren: false,
  errordescriptionShorteren: false,
  successdescriptionShorteren: null,
  story: [],

  userPromotionSuccess: false,
  userPromotionError: false,
  userPromotionErrorMsg: null,
  userPromotionLoading: false,
  userPromotion: null,

  errorMsgCheckNameNft: "",
  loadingCheckNameNft: false,
  errorCheckNameNft: false,
  successCheckNameNft: null,
  CheckNameNftText: [],
};

const UploadNft = (state = initialState, action) => {
  switch (action.type) {
    //UPLOAD_NFT
    case UPLOAD_NFT:
      state = {
        ...state,
        loadingUploadNft: true,
        errorUploadNft: false,
        successUploadNft: false,
        errorMsgUploadNft: null,
      };
      break;
    case UPLOAD_NFT_SUCCESS:
      state = {
        ...state,
        loadingUploadNft: false,
        errorMsgUploadNft: null,
        successUploadNft: true,
        form: action.payload,
      };
      break;
    case UPLOAD_NFT_ERROR:
      state = {
        ...state,
        errorMsgUploadNft: action.payload,
        loadingUploadNft: false,
        errorUploadNft: true,
        successUploadNft: false,
      };
      break;
    case RESET_UPLOAD_NFT:
      state = {
        ...state,
        errorMsgUploadNft: "",
        loadingUploadNft: false,
        errorUploadNft: false,
        successUploadNft: null,
        // form: [],
      };
      break;

    //GENERATE_DESCRIPTION
    case GENERATE_DESCRIPTION:
      state = {
        ...state,
        loadingNftdescription: true,
        errorNftdescription: false,
        successNftdescription: false,
        errorMsgNftdescription: null,
      };
      break;
    case GENERATE_DESCRIPTION_SUCCESS:
      state = {
        ...state,
        loadingNftdescription: false,
        errorMsgNftdescription: false,
        successNftdescription: true,
        name: action.payload,
      };
      break;
    case GENERATE_DESCRIPTION_ERROR:
      state = {
        ...state,
        errorMsgNftdescription: action.payload.data,
        loadingNftdescription: false,
        errorNftdescription: true,
        successNftdescription: false,
      };
      break;

    //SHORTEREN_DESCRIPTION
    case SHORTEREN_DESCRIPTION:
      state = {
        ...state,
        loadingdescriptionShorteren: true,
        errordescriptionShorteren: false,
        successdescriptionShorteren: false,
        errorMsgdescriptionShorteren: null,
      };
      break;
    case SHORTEREN_DESCRIPTION_SUCCESS:
      state = {
        ...state,
        loadingdescriptionShorteren: false,
        errorMsgdescriptionShorteren: false,
        successdescriptionShorteren: true,
        story: action.payload,
      };
      break;
    case SHORTEREN_DESCRIPTION_ERROR:
      state = {
        ...state,
        errorMsgdescriptionShorteren: action.payload.data,
        loadingdescriptionShorteren: false,
        errordescriptionShorteren: true,
        successdescriptionShorteren: false,
      };
      break;

    //check-name-nft
    case NFTS_CHECK_NAME:
      state = {
        ...state,
        loadingCheckNameNft: true,
        errorCheckNameNft: false,
        successCheckNameNft: false,
        errorMsgCheckNameNft: null,
      };
      break;
    case NFTS_CHECK_NAME_SUCCESS:
      state = {
        ...state,
        loadingCheckNameNft: false,
        errorMsgCheckNameNft: false,
        successCheckNameNft: true,
        CheckNameNftText: action.payload,
      };
      break;
    case NFTS_CHECK_NAME_ERROR:
      state = {
        ...state,
        errorMsgCheckNameNft: action.payload.data,
        loadingCheckNameNft: false,
        errorCheckNameNft: true,
        successCheckNameNft: false,
      };
      break;

    //RESET_NFTS_CHECK_NAME
    case RESET_NFTS_CHECK_NAME:
      state = {
        ...state,
        errorMsgCheckNameNft: "",
        loadingCheckNameNft: false,
        errorCheckNameNft: false,
        successCheckNameNft: false,
        CheckNameNftText: null,
      };
      break;

    //GET_USER_REFERRAL_PROMOTION
    case GET_USER_REFERRAL_PROMOTION:
      state = {
        ...state,
        userPromotionLoading: true,
        userPromotionError: false,
        userPromotionSuccess: false,
        userPromotionErrorMsg: null,
      };
      break;
    case GET_USER_REFERRAL_PROMOTION_SUCCESS:
      state = {
        ...state,
        userPromotionLoading: false,
        userPromotionErrorMsg: false,
        userPromotionSuccess: true,
        userPromotion: action.payload,
      };
      break;
    case GET_USER_REFERRAL_PROMOTION_ERROR:
      state = {
        ...state,
        userPromotionErrorMsg: action.payload.data,
        userPromotionLoading: false,
        userPromotionError: true,
        userPromotionSuccess: false,
      };
      break;
    case RESET_USER_REFERRAL_PROMOTION:
      state = {
        ...state,
        userPromotionSuccess: false,
        userPromotionError: false,
        userPromotionErrorMsg: null,
        userPromotionLoading: false,
        userPromotion: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default UploadNft;
