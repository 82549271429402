import {
  //POST_VERIFY_TWITTER_ACTION
  POST_VERIFY_TWITTER_ACTION,
  POST_VERIFY_TWITTER_ACTION_SUCCESS,
  POST_VERIFY_TWITTER_ACTION_ERROR,
  RESET_VERIFY_TWITTER_FLAG,
} from "./actionTypes";

const initialState = {
  //verifyTwitter
  verifyTwitter: null,
  verifyTwitterError: false,
  verifyTwitterSuccess: false,
  verifyTwitterLoading: null,
  verifyTwitterMessage: null,
};

const VerifyTwitter = (state = initialState, action) => {
  switch (action.type) {
    // POST_VERIFY_TWITTER_ACTION
    case POST_VERIFY_TWITTER_ACTION:
      state = {
        ...state,

        verifyTwitterError: false,
        verifyTwitterSuccess: false,
        verifyTwitterLoading: true,
      };
      break;
    case POST_VERIFY_TWITTER_ACTION_SUCCESS:
      state = {
        ...state,
        verifyTwitter: action.payload,
        verifyTwitterError: false,
        verifyTwitterSuccess: true,
        verifyTwitterLoading: null,
        verifyTwitterMessage: null,
      };
      break;
    case POST_VERIFY_TWITTER_ACTION_ERROR:
      state = {
        ...state,
        verifyTwitter: null,
        verifyTwitterError: true,
        verifyTwitterSuccess: false,
        verifyTwitterLoading: null,
        verifyTwitterMessage: action.payload,
      };
      break;

    case RESET_VERIFY_TWITTER_FLAG:
      state = {
        ...state,
        verifyTwitterError: false,
        verifyTwitterSuccess: false,
        verifyTwitterLoading: null,
        verifyTwitterMessage: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default VerifyTwitter;
