//READ
export const READ_CUSTOM_TASK = "READ_CUSTOM_TASK";
export const READ_CUSTOM_TASK_SUCCESS = "READ_CUSTOM_TASK_SUCCESS";
export const READ_CUSTOM_TASK_ERROR = "READ_CUSTOM_TASK_ERROR";

//READ-my-task
export const READ_MY_CUSTOM_TASK = "READ_MY_CUSTOM_TASK";
export const READ_MY_CUSTOM_TASK_SUCCESS = "READ_MY_CUSTOM_TASK_SUCCESS";
export const READ_MY_CUSTOM_TASK_ERROR = "READ_MY_CUSTOM_TASK_ERROR";

//UPDATE
export const UPDATE_CUSTOM_TASK = "UPDATE_CUSTOM_TASK";
export const UPDATE_CUSTOM_TASK_SUCCESS = "UPDATE_CUSTOM_TASK_SUCCESS";
export const UPDATE_CUSTOM_TASK_ERROR = "UPDATE_CUSTOM_TASK_ERROR";

//RESET FLAG
export const RESET_CUSTOM_TASK_LIST_FLAG = "RESET_CUSTOM_TASK_LIST_FLAG";
export const RESET_CUSTOM_TASK_UPDATE_FLAG = "RESET_CUSTOM_TASK_UPDATE_FLAG";
