import { all, call, fork, put, takeLatest } from "redux-saga/effects";

import {
  postCancelStakes,
  postGetMyStakes,
  postRequestWithdrawStakes,
} from "@/config/helpers/backend_helper";

import {
  cancelStakesError,
  cancelStakesSuccess,
  getMyStakesError,
  getMyStakesSuccess,
  requestStakesError,
  requestStakesSuccess,
} from "./actions";

import { CANCEL_STAKES, GET_MY_STAKES, REQUEST_STAKES } from "./actionTypes";

function* fetchGetMyStakes(action) {
  try {
    const response = yield call(postGetMyStakes, action.payload);
    yield put(getMyStakesSuccess(response));
  } catch (error) {
    yield put(getMyStakesError(error));
  }
}

function* fetchRequestStakes(action) {
  try {
    const response = yield call(postRequestWithdrawStakes, action.payload);
    yield put(requestStakesSuccess(response));
  } catch (error) {
    yield put(requestStakesError(error));
  }
}

function* fetchCancelStakes({ payload: { data } }) {
  try {
    const response = yield call(postCancelStakes, data);
    yield put(cancelStakesSuccess(response));
  } catch (error) {
    yield put(cancelStakesError(error));
  }
}

export function* watchTwitterStakes() {
  yield takeLatest(GET_MY_STAKES, fetchGetMyStakes);
  yield takeLatest(REQUEST_STAKES, fetchRequestStakes);
  yield takeLatest(CANCEL_STAKES, fetchCancelStakes);
}

function* TwitterStakesSaga() {
  yield all([fork(watchTwitterStakes)]);
}

export default TwitterStakesSaga;
