import {
  GET_ALL_WALLET_BALANCE,
  GET_ALL_WALLET_BALANCE_SUCCESS,
  GET_ALL_WALLET_BALANCE_ERROR,
  RESET_ALL_WALLET_BALANCE_STATES,
  RESET_ALL_WALLET_BALANCE_DATA_FLAG,
} from "./actionTypes";
const initialState = {
  error: null,
  success: null,
  balance: [],
  message: null,
  loading: null,
};

const WalletBalance = (state = initialState, action) => {
  switch (action.type) {
    //GET_MY_PROFILE
    case GET_ALL_WALLET_BALANCE:
      state = {
        ...state,
        loading: true,
        success: false,
        error: false,
      };
      break;
    case GET_ALL_WALLET_BALANCE_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: true,
        error: false,
        balance: action.payload,
      };
      break;
    case GET_ALL_WALLET_BALANCE_ERROR:
      state = {
        ...state,
        loading: false,
        success: false,
        error: true,
        balance: null,
        message: action.payload,
      };
      break;
    //RESET_FLAGS
    case RESET_ALL_WALLET_BALANCE_STATES:
      state = {
        ...state,
        success: null,
        error: null,
        message: null,
        loading: null,
        balance: [],
      };
      break;
    case RESET_ALL_WALLET_BALANCE_DATA_FLAG:
      state = {
        ...state,
        success: null,
        error: null,
        balance: [],
        message: null,
        loading: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  // console.log("profile state:", state.user);
  return state;
};

export default WalletBalance;
