// actions.js
import {
  GET_COUNTRIES_ACTION,
  GET_COUNTRIES_ACTION_SUCCESS,
  GET_COUNTRIES_ACTION_ERROR,
  GET_PROVINCES_ACTION,
  GET_PROVINCES_ACTION_SUCCESS,
  GET_PROVINCES_ACTION_ERROR,
  GET_CITIES_ACTION,
  GET_CITIES_ACTION_SUCCESS,
  GET_CITIES_ACTION_ERROR,
  POST_SHIPPING_ADDRESS_ACTION,
  POST_SHIPPING_ADDRESS_ACTION_SUCCESS,
  POST_SHIPPING_ADDRESS_ACTION_ERROR,
  RESET_SHIPPING_ADDRESS_ACTION,
  GET_SHIPPING_ADDRESS_ACTION,
  GET_SHIPPING_ADDRESS_ACTION_SUCCESS,
  GET_SHIPPING_ADDRESS_ACTION_ERROR,
} from "./actionTypes";

//GET_COUNTRIES_ACTION
export const getCountryAction = () => ({
  type: GET_COUNTRIES_ACTION,
});

export const getCountryActionSuccess = (data) => ({
  type: GET_COUNTRIES_ACTION_SUCCESS,
  payload: data,
});

export const getCountryActionError = (error) => ({
  type: GET_COUNTRIES_ACTION_ERROR,
  payload: error,
});

//GET_PROVINCES_ACTION
export const getProvincesAction = (data) => ({
  type: GET_PROVINCES_ACTION,
  payload: { data },
});

export const getProvincesActionSuccess = (data) => ({
  type: GET_PROVINCES_ACTION_SUCCESS,
  payload: data,
});

export const getProvincesActionError = (error) => ({
  type: GET_PROVINCES_ACTION_ERROR,
  payload: error,
});

//GET_CITIES_ACTION
export const getCitiesAction = (data) => ({
  type: GET_CITIES_ACTION,
  payload: { data },
});

export const getCitiesActionSuccess = (data) => ({
  type: GET_CITIES_ACTION_SUCCESS,
  payload: data,
});

export const getCitiesActionError = (error) => ({
  type: GET_CITIES_ACTION_ERROR,
  payload: error,
});

//ShippingAddress
export const ShippingAddressAction = (data) => ({
  type: POST_SHIPPING_ADDRESS_ACTION,
  payload: { data },
});

export const ShippingAddressActionSuccess = (data) => ({
  type: POST_SHIPPING_ADDRESS_ACTION_SUCCESS,
  payload: data,
});

export const shippingAddressActionError = (error) => ({
  type: POST_SHIPPING_ADDRESS_ACTION_ERROR,
  payload: error,
});

//ShippingAddressDetails
export const shippingAddressDetailsAction = (data) => ({
  type: GET_SHIPPING_ADDRESS_ACTION,
  payload: { data },
});

export const shippingAddressDetailsActionSuccess = (data) => ({
  type: GET_SHIPPING_ADDRESS_ACTION_SUCCESS,
  payload: data,
});

export const shippingAddressDetailsActionError = (error) => ({
  type: GET_SHIPPING_ADDRESS_ACTION_ERROR,
  payload: error,
});

//reset
export const resetShippingAddressAction = () => ({
  type: RESET_SHIPPING_ADDRESS_ACTION,
});
