import { all, call, fork, put, takeLatest } from "redux-saga/effects";

import {
  postVerifyTwitterActionError,
  postVerifyTwitterActionSuccess,
} from "./actions";

import { postVerifyTwitter } from "@/config/helpers/backend_helper";

import { POST_VERIFY_TWITTER_ACTION } from "./actionTypes";

function* fetchVerifyTwitter(action) {
  try {
    const response = yield call(postVerifyTwitter, action.payload);
    yield put(postVerifyTwitterActionSuccess(response));
  } catch (error) {
    yield put(
      postVerifyTwitterActionError(error.response.data.Errors[0].Message)
    );
  }
}

export function* VerifyTwittersagaChange() {
  yield takeLatest(POST_VERIFY_TWITTER_ACTION, fetchVerifyTwitter);
}

function* VerifyTwittersaga() {
  yield all([fork(VerifyTwittersagaChange)]);
}

export default VerifyTwittersaga;
