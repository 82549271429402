// saga.js
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  getCities,
  getCountry,
  getProvinces,
  getShippingAddressDetails,
  postShippingAddress,
} from "@/config/helpers/backend_helper";
import {
  getCitiesActionError,
  getCitiesActionSuccess,
  getCountryActionError,
  getCountryActionSuccess,
  getProvincesActionError,
  getProvincesActionSuccess,
  shippingAddressActionError,
  ShippingAddressActionSuccess,
  shippingAddressDetailsActionError,
  shippingAddressDetailsActionSuccess,
} from "./action";
import {
  GET_CITIES_ACTION,
  GET_COUNTRIES_ACTION,
  GET_PROVINCES_ACTION,
  GET_SHIPPING_ADDRESS_ACTION,
  POST_SHIPPING_ADDRESS_ACTION,
} from "./actionTypes";

function* fetchCountryAction() {
  try {
    const response = yield call(getCountry);
    yield put(getCountryActionSuccess(response));
  } catch (error) {
    yield put(getCountryActionError(error.response.data.Errors[0].Message));
  }
}

function* fetchProvincesAction({ payload: { data } }) {
  try {
    const response = yield call(getProvinces, data);
    yield put(getProvincesActionSuccess(response));
  } catch (error) {
    yield put(getProvincesActionError(error.response.data.Errors[0].Message));
  }
}

function* fetchCityAction({ payload: { data } }) {
  try {
    const response = yield call(getCities, data);
    yield put(getCitiesActionSuccess(response));
  } catch (error) {
    yield put(getCitiesActionError(error.response.data.Errors[0].Message));
  }
}

function* fetchShippingAddress({ payload: { data } }) {
  try {
    const response = yield call(postShippingAddress, data);
    yield put(ShippingAddressActionSuccess(response));
  } catch (error) {
    yield put(
      shippingAddressActionError(error?.response?.data?.Errors[0]?.Message)
    );
  }
}

function* fetchShippingAddressDetails({ payload: { data } }) {
  try {
    const response = yield call(getShippingAddressDetails, data);
    yield put(shippingAddressDetailsActionSuccess(response));
  } catch (error) {
    yield put(shippingAddressDetailsActionError(error));
  }
}

export function* watchShippingAddress() {
  yield takeEvery(GET_COUNTRIES_ACTION, fetchCountryAction);
  yield takeEvery(GET_PROVINCES_ACTION, fetchProvincesAction);
  yield takeEvery(GET_CITIES_ACTION, fetchCityAction);
  yield takeEvery(POST_SHIPPING_ADDRESS_ACTION, fetchShippingAddress);
  yield takeEvery(GET_SHIPPING_ADDRESS_ACTION, fetchShippingAddressDetails);
}

function* ShippingAddressSaga() {
  yield all([fork(watchShippingAddress)]);
}

export default ShippingAddressSaga;
