import crypto, { randomBytes, randomUUID } from "crypto";

export const api_stage = {
  API_URL_STAGE1: "https://stage1.seedphoto.dev/",
  API_URL_STAGE2: "https://stage2.seedphoto.dev/",
  API_URL_STAGE3: "https://stage3.seedphoto.dev/",
  API_URL_MAIN: "https://api.seed.photo/",
};

export const api_stage_twitter = {
  API_URL_STAGE1: "https://twitter.seedphoto.dev",
  API_URL_MAIN: "https://twitter.seed.photo",
};

export const stage_env = {
  stage: process.env.NEXT_PUBLIC_STAGE,
};

export const web3Configs = {
  projectId_Main: "ee9718cba6351426f9cc94d4cd8ed051",
  projectId_stage: "aaf759f367d2816e382d8699c3e1eceb",
};

export const metadataWalletConnect = {
  name: "Seed Photo",
  description: "SEED.Photo is a NFT minting platform for Photographers.",
  url: `${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}`,
  // icons: ["/apple-touch-icon"],
};

//BAu1zNHx8bucLSR87IZDRBtYUMLmrXVoi5FEg7Nb7b6Ae7QGLRf3k8q3wuY9FqTeofzdSDyx7Eazt8Eu72ygpwM
export const web3AuthClientId = {
  TestNet:
    "BGgtd-ejefdIRR32FkmiXHIxAhotjD0GY41-Opp6eacNVAtAEbFbLoDwn--t-OMJvuEsodmTGQD3fnnxXzu1MwE",
  MainNet:
    "BFm3Zs8NmM8f3pLtcK_r9qHr8Bfun-A55W4YRYteT2biR_nOKPt_mkRRXIJphIP0-RhLX7Yf0wpUaqLDJiLEzY0",
};

export const TwitterConfig = {
  //FS
  // TWITTER_CLIENT_ID: "Mm9QWTBaQ19PemlJTFA1N1FsT2w6MTpjaQ",
  // TWITTER_CLIENT_SECRET: "q39dJphOdCCiPeJLDnvcJ04O4t7RyTOlZ7F63lqszG6_dpdP1c",
  //-----
  //SH
  // TWITTER_CLIENT_ID: "enpEaXZ3MVROSHJGRjF1V2dweXQ6MTpjaQ",
  // TWITTER_CLIENT_SECRET: "_XHOYcdqDkmsbDOJpIOfy_jLEu7hfQ3cal_7M4UHMPinpq18Ot",
  //-----
  // FS2
  // TWITTER_CLIENT_ID: "SWdvajFPbDZ4blV4M1Z1RVhlX2k6MTpjaQ",
  // TWITTER_CLIENT_SECRET: "Exf5Ep2ezH_jdje4B-eBl5905aeVDNkkWdlUku35BuNMl6U4aZ",
  // ---

  stage: {
    TWITTER_CLIENT_ID: "Mm9QWTBaQ19PemlJTFA1N1FsT2w6MTpjaQ",
    TWITTER_CLIENT_SECRET: "q39dJphOdCCiPeJLDnvcJ04O4t7RyTOlZ7F63lqszG6_dpdP1c",
  },

  stage2: {
    TWITTER_CLIENT_ID: "SWdvajFPbDZ4blV4M1Z1RVhlX2k6MTpjaQ",
    TWITTER_CLIENT_SECRET: "Exf5Ep2ezH_jdje4B-eBl5905aeVDNkkWdlUku35BuNMl6U4aZ",
  },

  production: {
    TWITTER_CLIENT_ID: "enpEaXZ3MVROSHJGRjF1V2dweXQ6MTpjaQ",
    TWITTER_CLIENT_SECRET: "_XHOYcdqDkmsbDOJpIOfy_jLEu7hfQ3cal_7M4UHMPinpq18Ot",
  },

  // SECRET: "1OlBl8WmG5Xm/Ub3EPyywn4F+krxLbtDUcabO65/sRc=",
  SECRET: randomUUID?.() ?? randomBytes(32).toString("hex"),
};
