import {
  //POST_VERIFY_TWITTER_ACTION
  POST_VERIFY_TWITTER_ACTION,
  POST_VERIFY_TWITTER_ACTION_ERROR,
  POST_VERIFY_TWITTER_ACTION_SUCCESS,
  RESET_VERIFY_TWITTER_FLAG,
} from "./actionTypes";

//postVerifyTwitter
export const postVerifyTwitterAction = (data) => {
  return {
    type: POST_VERIFY_TWITTER_ACTION,
    payload: data,
  };
};

export const postVerifyTwitterActionSuccess = (data) => {
  return {
    type: POST_VERIFY_TWITTER_ACTION_SUCCESS,
    payload: data,
  };
};

export const postVerifyTwitterActionError = (data) => {
  return {
    type: POST_VERIFY_TWITTER_ACTION_ERROR,
    payload: data,
  };
};

export const resetPostVerifyTwitterFlag = () => {
  return {
    type: RESET_VERIFY_TWITTER_FLAG,
  };
};
